$(document).ready(function () {
  ("use strict");

  // Menú: afegim botó i ocultem menú quan hi ha js
  $("#menu")
    .before(
      '<button class="hamburger hamburger--spring" type="button" aria-expanded="false" aria-controls="menu"><span class="hamburger-box"><span class="hamburger-inner"></span></span><span class="is-sr-only">Menu</span></button>'
    )
    .attr("hidden", "true");

  // ----
  // menú (https://polypane.rocks/blog/the-perfect-responsive-menu-2019/)
  // ----
  var toggleMenu = document.querySelector(".navigation button");
  var menu = document.querySelector("#menu");

  toggleMenu.addEventListener("click", function () {
    var open = JSON.parse(toggleMenu.getAttribute("aria-expanded"));
    toggleMenu.setAttribute("aria-expanded", !open);
    menu.hidden = !menu.hidden;
  });

  $(".hamburger").on("click", function () {
    // afegir classe al botó
    $(this).toggleClass("is-active");
    // afegir classe al body
    // $("body").toggleClass("js-menu-open");
  });

  // ---------
  // skip link (https://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/)
  // ---------

  // bind a click event to the 'skip' link
  $(".skip-link").click(function (event) {
    // strip the leading hash and declare
    // the content we're skipping to
    var skipTo = "#" + this.href.split("#")[1];

    // Setting 'tabindex' to -1 takes an element out of normal
    // tab flow but allows it to be focused via javascript
    $(skipTo)
      .attr("tabindex", -1)
      .on("blur focusout", function () {
        // when focus leaves this element,
        // remove the tabindex attribute
        $(this).removeAttr("tabindex");
      })
      .focus(); // focus on the content container
  });

  // ----------
  // scroll top
  // ----------
  $(window).scroll(function () {
    if ($(this).scrollTop() > 400) {
      $(".scrolltop").addClass("visible");
    } else {
      $(".scrolltop").removeClass("visible");
    }
  });

  // ------
  // Slider
  // ------
  $(".slider").slick({
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    arrows: false,
    customPaging: function (slider, i) {
      var thumb = $(slider.$slides[i]).data();
      return (
        '<a class="dot is-size-7 has-background-black has-text-white rounded is-flex is-justify-content-center is-flex-direction-column is-align-items-center">' +
        (i + 1) +
        "</a>"
      );
    },
    // responsive: [{
    //     breakpoint: 500,
    //     settings: {
    //         dots: false,
    //         infinite: false,
    //         slidesToShow: 2,
    //         slidesToScroll: 2
    //     }
    // }]
  });

  // --------
  // headroom
  // --------
  // $(".main-header").headroom(
  // {
  // offset: 0,
  // tolerance: 5,
  // classes: {
  //   initial: "animated",
  //   pinned: "slideDown",
  //   unpinned: "slideUp"
  // }
  // }
  // );

  // -----------
  // File upload
  // -----------
  $('input[type="file"]').change(function () {
    var i = $(this).next().find(".file-label").clone();
    var file = $('input[type="file"]')[0].files[0].name;
    $(this).next().find(".file-label").text(file);
  });

  // ----
  // Tabs https://inclusive-components.design/tabbed-interfaces/
  // ----
  if ($(".tabbed").length > 0) {
    $(".tabbed").each(function (index, element) {
      // Get relevant elements and collections
      var tabbed = element;
      var tablist = tabbed.querySelector("ul.tab-menu");
      var tabs = tablist.querySelectorAll("a");
      var panels = tabbed.querySelectorAll(".tab-panel");

      // The tab switching function
      var switchTab = function switchTab(oldTab, newTab) {
        newTab.focus(); // Make the active tab focusable by the user (Tab key)

        newTab.removeAttribute("tabindex"); // Set the selected state

        newTab.setAttribute("aria-selected", "true");
        oldTab.removeAttribute("aria-selected");
        oldTab.setAttribute("tabindex", "-1"); // Get the indices of the new and old tabs to find the correct
        // tab panels to show and hide

        var index = Array.prototype.indexOf.call(tabs, newTab);
        var oldIndex = Array.prototype.indexOf.call(tabs, oldTab);
        panels[oldIndex].hidden = true;
        panels[index].hidden = false;
      }; // Add the tablist role to the first <ul> in the .tabbed container

      tablist.setAttribute("role", "tablist"); // Add semantics are remove user focusability for each tab

      Array.prototype.forEach.call(tabs, function (tab, i) {
        tab.setAttribute("role", "tab");
        //	    tab.setAttribute("id", "tab" + (i + 1));
        tab.setAttribute("tabindex", "-1");
        tab.parentNode.setAttribute("role", "presentation"); // Handle clicking of tabs for mouse users

        tab.addEventListener("click", function (e) {
          e.preventDefault();
          var currentTab = tablist.querySelector("[aria-selected]");

          if (e.currentTarget !== currentTab) {
            switchTab(currentTab, e.currentTarget);
          }
        }); // Handle keydown events for keyboard users

        tab.addEventListener("keydown", function (e) {
          // Get the index of the current tab in the tabs node list
          var index = Array.prototype.indexOf.call(tabs, e.currentTarget); // Work out which key the user is pressing and
          // Calculate the new tab's index where appropriate

          var dir =
            e.which === 37
              ? index - 1
              : e.which === 39
              ? index + 1
              : e.which === 40
              ? "down"
              : null;

          if (dir !== null) {
            e.preventDefault(); // If the down key is pressed, move focus to the open panel,
            // otherwise switch to the adjacent tab

            dir === "down"
              ? panels[i].focus()
              : tabs[dir]
              ? switchTab(e.currentTarget, tabs[dir])
              : void 0;
          }
        });
      }); // Add tab panel semantics and hide them all

      Array.prototype.forEach.call(panels, function (panel, i) {
        panel.setAttribute("role", "tabpanel");
        panel.setAttribute("tabindex", "-1");
        var id = panel.getAttribute("id");
        panel.setAttribute("aria-labelledby", tabs[i].id);
        panel.hidden = true;
      }); // Initially activate the first tab and reveal the first tab panel

      tabs[0].removeAttribute("tabindex");
      tabs[0].setAttribute("aria-selected", "true");
      panels[0].hidden = false;
    });
  }
});

// -----------
// Collapsible (https://inclusive-components.design/collapsible-sections/)
// -----------
(function () {
  // Get all the headings
  const headings = document.querySelectorAll(".collapsible__title");

  Array.prototype.forEach.call(headings, (heading) => {
    // Give each <h2> a toggle button child
    // with the SVG plus/minus icon
    heading.innerHTML = `
      <button aria-expanded="false" class="is-flex is-justify-content-space-between is-align-content-center px-3 py-4">
        ${heading.textContent}
        <svg width="24" height="15" xmlns="http://www.w3.org/2000/svg">
          <path stroke="#000" stroke-width="4" d="m2 2 10 10L22 2" fill="none" fill-rule="evenodd"/>
        </svg>
      </button>
    `;
    // Function to create a node list
    // of the content between this heading and the next
    const getContent = (elem) => {
      let elems = [];
      while (
        elem.nextElementSibling &&
        elem.nextElementSibling.className !== "collapsible__title"
      ) {
        elems.push(elem.nextElementSibling);
        elem = elem.nextElementSibling;
      }

      // Delete the old versions of the content nodes
      elems.forEach((node) => {
        node.parentNode.removeChild(node);
      });

      return elems;
    };

    // Assign the contents to be expanded/collapsed (array)
    let contents = getContent(heading);

    // Create a wrapper element for `contents` and hide it
    let wrapper = document.createElement("div");
    wrapper.classList.add("flow");
    wrapper.hidden = true;

    // Add each element of `contents` to `wrapper`
    contents.forEach((node) => {
      wrapper.appendChild(node);
    });

    // Add the wrapped content back into the DOM
    // after the heading
    heading.parentNode.insertBefore(wrapper, heading.nextElementSibling);

    // Assign the button
    let btn = heading.querySelector("button");

    btn.onclick = () => {
      // Cast the state as a boolean
      let expanded = btn.getAttribute("aria-expanded") === "true" || false;

      // Switch the state
      btn.setAttribute("aria-expanded", !expanded);
      // Switch the content's visibility
      wrapper.hidden = expanded;
    };
  });

  if (
    $(".collapsible__title").length > 0 &&
    window.location.hash.indexOf("#entry-") > -1 &&
    $(window.location.hash).length > 0
  ) {
    $(window.location.hash).find("button").attr("aria-expanded", true);
    $(window.location.hash).next().show();
    $("html, body").animate(
      {
        scrollTop: $(window.location.hash).parent().offset().top,
      },
      1000
    );
  }

  if (typeof idioma !== "undefined" && idioma == "fr") {
    var language = {
      lengthMenu: "Afficher _MENU_ entrées",
      zeroRecords: "Rien trouvé",
      info: "Montrant la page _PAGE_ sur _PAGES_",
      infoEmpty: "Aucun enregistrement disponible",
      infoFiltered: "(filtré à partir de _MAX_ enregistrements au total)",
      search: "Chercher:",
      paginate: {
        first: "Première",
        last: "Dernière",
        next: "Suivante",
        previous: "Précédente",
      },
    };
  } else if (typeof idioma !== "undefined" && idioma == "ca") {
    var language = {
      lengthMenu: "Mostrant _MENU_ entrades per pàgina",
      zeroRecords: "No s'ha trobat cap entrada",
      info: "Mostrant pàgina _PAGE_ de _PAGES_",
      infoEmpty: "Cap entrada disponible",
      infoFiltered: "(filtrant entre _MAX_ entrades)",
      search: "Cerca:",
      paginate: {
        first: "Primera",
        last: "Última",
        next: "Següent",
        previous: "Anterior",
      },
    };
  }

  $("table.dataTable").each(function (index) {
    $("#table-professionals").DataTable({
      language: language,
      responsive: true,
      // "columns": [
      //   null,
      //   null,
      //   { "min-width": "85%" },
      //   null,
      //   null
      // ],
      // 'createdRow': function(row, data, dataIndex){
      //   $('td:eq(2)', row).css('min-width', '130px');
      // },
      // "fnDrawCallback": function (oSettings) {
      //   if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
      //     $(oSettings.nTableWrapper).find('.dataTables_paginate').hide();
      //   } else {
      //       $(oSettings.nTableWrapper).find('.dataTables_paginate').show();
      //   };
      //   // $("#table-professionals_previous").addClass("prev");
      //   // $(".disabled").addClass("unavailable").removeClass(".disabled");

      // },
    });
  });
})();
